import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message as msg } from 'antd';
import { getSetting, updateSetting, initSetting } from "../features/setting/settingSlice";
import { Button, Form, Input, Switch } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const { TextArea } = Input;
// import { Switch } from 'antd';

const Setting = () => {
  const [messageApi, contextHolder] = msg.useMessage();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setting, isError, updating, message, isUpdateSuccess } = useSelector((state) => state.setting);

  /** Check for api error and call api for init data */
  useEffect(() => {
    dispatch(initSetting());
    if (isError && message) {
      messageApi.open({
        type: 'error',
        content: message,
      });
    }
    if (isUpdateSuccess) {
      messageApi.open({
        type: 'success',
        content: "Setting is updated successfully",
      });
    }

    dispatch(getSetting());

  }, [isError, message, messageApi, dispatch, isUpdateSuccess]);

  /** Set default values in form */
  useEffect(() => {
    if (setting) {
      form.setFieldsValue({
        restrictedWord: setting?.restrictedWord || '',
        swipeLimit: setting?.swipeLimit || 50,
        swipeLimitDuration: setting?.swipeLimitDuration || 24,
        termsAndConditions: setting?.termsAndConditions || '',
        userAgreement: setting?.userAgreement || '',
        privacyPolicy: setting?.privacyPolicy || '',
        tips: setting.tips || '',
        safety: setting.safety || '',
        SignUP: setting.SignUP || '',
        SafetySecurityPrivacy: setting.SafetySecurityPrivacy || '',
        VerifiedProfile: setting.VerifiedProfile || '',
        YachtRideFeatures: setting.YachtRideFeatures || '',
        MainFeed: setting.MainFeed || '',
        DiamondPass: setting.DiamondPass || '',
        PlanningYourDreamDate: setting.PlanningYourDreamDate || '',
        HowItWorks: setting.HowItWorks || '',
        UnlimitedLikes: setting.UnlimitedLikes || '',
        FuturePromos: setting.FuturePromos || '',
        islandPassTitle: setting.islandPassTitle || '',
        islandPassDescription: setting.islandPassDescription || '',
        showRealFeed: setting.showRealFeed || false,
        enableRegister: setting.enableRegister || false,
      });
    }
  }, [setting, form]);


  /** On submit call api */
  const onFinish = (values) => {
    const convertedValues = {
      ...values,
      swipeLimitDuration: Number(values.swipeLimitDuration),
      swipeLimit: Number(values.swipeLimit),
    };
    console.log('Converted Values:', convertedValues);
    dispatch(updateSetting(convertedValues));
  };

  /** If there is error on form submission */
  const onFinishFailed = (errorInfo) => {
    messageApi.open({
      type: 'error',
      content: errorInfo.message || 'Error on form submission',
    });
  };

  // const onChange = (checked) => {
  //   console.log(`switch to ${checked}`);
  // };

  return (
    <>
      {contextHolder}
      <div>
        <h3 className="mb-4 title">Setting</h3>
        <div className="d-flex gap-3">
          <div className="d-flex flex-grow-1 bg-white p-3 roudned-3">
            <div className="p-4 bg-white rounded-3" style={{ width: "60%" }}>
              <Form
                form={form}
                name="basic"
                layout="vertical"  // Set the layout to vertical
                initialValues={{
                  restrictedWord: setting?.restrictedWord || 'hello hi how are you',
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Restricted word for chat"
                  name="restrictedWord"
                  rows={6}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Restricted word  for chat!',
                    },
                  ]}
                >
                  <TextArea />
                </Form.Item>

                <Form.Item
                  label="Right swipe limit"
                  name="swipeLimit"
                  type="number"
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  label="Swipe limit duration (hr)"
                  name="swipeLimitDuration"
                  type="number"
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  label="Discover Section Title"
                  name="islandPassTitle"
                  type="text"
                >
                  <Input type="text" />
                </Form.Item>

                <Form.Item
                  label="Discover Section Description"
                  name="islandPassDescription"
                  type="text"
                >
                  <Input type="text" />
                </Form.Item>
              
                 {/* Switch component for notifications */}
                 <Form.Item
                  label="Show Real Feed"
                  name="showRealFeed"
                  valuePropName="checked" // Ensure checked prop controls switch state
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  label="Terms and Conditions"
                  name="termsAndConditions"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Privacy Policy"
                  name="privacyPolicy"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="User Agreement"
                  name="userAgreement"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Tips for connection"
                  name="tips"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Safety, Security and Privacy"
                  name="safety"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Signup"
                  name="SignUP"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Safety Security Privacy"
                  name="SafetySecurityPrivacy"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Verified Profile"
                  name="VerifiedProfile"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Yacht Ride Features"
                  name="YachtRideFeatures"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Main Feed"
                  name="MainFeed"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Diamond Pass"
                  name="DiamondPass"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Planning Your Dream Date"
                  name="PlanningYourDreamDate"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="How It Works"
                  name="HowItWorks"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Unlimited Likes"
                  name="UnlimitedLikes"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>

                <Form.Item
                  label="Future Promos"
                  name="FuturePromos"
                >
                  <ReactQuill theme="snow" style={{ height: '150px', marginBottom: "40px" }} />
                </Form.Item>


                <Form.Item
                  label="Enable Register"
                  name="enableRegister"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button disabled={updating} type="primary" htmlType="submit">
                    {updating ? 'Update ...' : 'Update'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
